<script>
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            page: 1,
            showBanner: true,
            timer: null,
            totalPages: 1,
            isDesktop: true,
        };
    },
    computed: {
        ...mapGetters({
            Banner: 'home/Banner',
        }),
    },
    watch: {
        Banner: {
            handler() {
                const total = this.Banner.length;
                this.totalPages = total;
            },
        },
    },
    methods: {
        resetEffects() {
            this.timer = setTimeout(() => {
                this.showBanner = false;
                this.$nextTick(() => {
                    this.showBanner = true;
                });

                if (this.page >= this.totalPages) {
                    this.page = 1;
                } else {
                    this.page = +1;
                    this.resetEffects();
                }
            }, 5000);
        },
        onWindowResize() {
            this.windowWidth = window.innerWidth;
            if (this.windowWidth <= 768) {
                this.isDesktop = false;
            } else {
                this.isDesktop = true;
            }
        },
    },
    mounted() {
        this.onWindowResize();
        this.resetEffects();
        if (this.Banner.length >= 1) {
            this.totalPages = this.Banner.length;
        }
    },
    created() {
        this.$store.dispatch('home/get_Banner');
        window.addEventListener('resize', this.onWindowResize);
    },
    destroyed() {
        window.removeEventListener('resize', this.onWindowResize);
    },
};
</script>
<template>
    <b-container fluid class="p-0" :class="isDesktop ? 'banner-desktop' : 'banner-mobile'">
      <template v-if="false">
        <div class="navigation-banner" >
            <div
                class="navigation navigation-1 animate__animated animate__slideInLeft"
            >
                <img src="~@/assets/images/mock/banner/mini 1.png" alt="" />
            </div>
            <div
                class="navigation navigation-2 animate__animated animate__slideInLeft animate__delay-1s"
            >
                <img src="~@/assets/images/mock/banner/mini 2.png" alt="" />
            </div>
            <div
                class="navigation navigation-3 animate__animated animate__slideInLeft animate__delay-2s"
            >
                <img src="~@/assets/images/mock/banner/mini 3.png" alt="" />
            </div>
        </div>
        <div
            class="top-vector vector-banner animate__animated animate__slideInDown"
        >
            <img
                src="~@/assets/images/mock/banner/Up_right.png"
                alt=""
                class="desktop"
            />
            <img
                src="~@/assets/images/mock/banner/Up_right_mb.png"
                alt=""
                class="mobile"
            />
        </div>
        <div
            class="bot-left-vector vector-banner animate__animated animate__slideInUp"
        >
            <img
                src="~@/assets/images/mock/banner/down_right.png"
                alt=""
                class="desktop"
            />
            <img
                src="~@/assets/images/mock/banner/down_right_mb.png"
                alt=""
                class="mobile"
            />
        </div>
        <div
            class="bot-right-vector vector-banner animate__animated animate__slideInLeft"
        >
            <img
                src="~@/assets/images/mock/banner/down_left.png"
                alt=""
                class="desktop"
            />
            <img
                src="~@/assets/images/mock/banner/down_left_mb.png"
                alt=""
                class="mobile"
            />
        </div>
        <div
            class="top-right-vector vector-banner animate__animated animate__fadeIn"
        >
            <img
                src="~@/assets/images/mock/banner/br1.png"
                alt=""
                class="desktop"
            />
            <img
                src="~@/assets/images/mock/banner/br1_mb.png"
                alt=""
                class="mobile"
            />
        </div>

        <b-container
            v-if="showBanner"
            class="d-flex justify-content-end align-items-center container-banner animate__animated animate__fadeInRight"
        >
            <div class="text">
                <div class="title">
                    <div class="gradiant">Pi Investment</div>
                    <div class="normal">Fund</div>
                </div>
                <div class="sub-title" v-if="page == 1">
                    {{ $t('slogan') }}
                </div>
                <div class="sub-title" v-if="page == 2">
                    {{ $t('businessConnection') }}
                </div>
                <div class="button">
                    <a href="https://system.pifund.io/" target="_blank">{{
                        $t('becomeInvest')
                    }}</a>
                </div>
            </div>
        </b-container>
      </template>
    </b-container>
</template>

<style lang="scss">
.hero-banner {
    height: 100vh;
    width: 100vw !important;
    background-image: url('~@/assets/images/background/banner-desktop.jpg');
    background-repeat: no-repeat;
    background-position: left;
    background-size: cover;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    min-height: 800px;
    overflow: hidden;
    .mobile {
        display: none;
    }
    &:after {
        content: '';
        width: 100%;
        height: 6px;
        position: absolute;
        bottom: 0px;
        left: 0;
        right: 0;
        background: linear-gradient(172deg, #237fff 0%, #0930ff 100%);
    }
    .navigation-banner {
        position: absolute;
        left: 50px;
        top: 120px;
        height: auto;
        z-index: 10;
        .navigation {
            width: 10vw;
            max-width: 190px;
            height: calc(10vw - 20px);
            margin-bottom: 20px;
            border-radius: 25px;
            box-shadow: 2px 2px 10px 5px rgba($color: #000000, $alpha: 0.3);
            img {
                width: 100%;
                height: 100%;
            }
        }
    }
    .vector-banner {
        position: absolute;
        img {
            width: 100%;
            height: auto;
        }
    }
    .top-vector {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 65vw;
    }
    .bot-left-vector {
        position: absolute;
        bottom: 5px;
        left: 0;
        z-index: 2;
        width: 65vw;
        img {
            filter: drop-shadow(-2px -5px 5px rgba(0, 0, 0, 0.541));
        }
    }
    .bot-right-vector {
        position: absolute;
        bottom: 5px;
        right: 5px;
        z-index: 1;
        img {
            height: 100px;
        }
    }
    .top-right-vector {
        position: absolute;
        top: 5px;
        right: 0;
        z-index: 0;
    }
    .text {
        font-family: 'Roboto', sans-serif;
        position: relative;
        z-index: 20;
        .title {
            display: flex;
            align-items: baseline;
            @media (max-width: 480px) {
                justify-content: flex-end;
            }
            .gradiant {
                font-size: clamp(40px, 4vw, 72px);
                background: -webkit-linear-gradient(
                    -19deg,
                    #237fff 0%,
                    #0930ff 100%
                );
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-weight: 700;
            }
            .normal {
                color: #232323;
                font-weight: 600;
                font-size: clamp(30px, 4vw, 52px);
                line-height: 100%;
                margin-left: 15px;
            }
        }
        .sub-title {
            color: #003189;
            font-weight: 600;
            font-size: 32px;
            line-height: 100%;
            margin-left: 15px;
            text-align: right;
        }
        .button {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin: 20px 0;
            a {
                background: linear-gradient(172deg, #237fff 0%, #0930ff 100%);
                height: 50px;
                width: 275px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 2px solid #fff;
                border-radius: 25px;
                box-shadow: 2px 2px 10px 5px rgba($color: #000000, $alpha: 0.3);
                color: #ffff;
                font-weight: 600;
                text-decoration: none;
                transition: all 0.5s;
                &:hover {
                    transform: scale(1.05);
                }
            }
        }
    }
    .container-banner {
        position: relative;
        z-index: 50;
    }
    @media (max-width: 1199px) {
        .navigation-banner {
            .navigation {
                width: 12vw;
                max-width: 150px;
                height: calc(12vw - 20px);
            }
        }
        .top-vector {
            width: 100vw;
        }
        .bot-left-vector {
            width: 80vw;
        }
        .bot-right-vector {
            img {
                height: 50px;
                width: auto;
            }
        }
        .top-right-vector {
            width: 50vw;
            top: 15%;
        }
        .text {
            padding-right: 0px;
        }
    }
    @media (max-width: 991px) {
        min-height: 600px;
        height: auto;
    }

    @media (max-width: 767px) {
        min-height: 500px;
        .container-banner {
            max-width: 100%;
            padding: 15px;
        }
        .desktop {
            display: none;
        }
        .mobile {
            display: block;
        }
        .navigation-banner {
            left: 5px;
            top: 100px;
            .navigation {
                width: 80px;
                height: 60px;
            }
        }
        .top-vector {
            position: absolute;
            top: 0;
            left: -15px;
            z-index: 1;
            width: 100%;
        }
        .bot-left-vector {
            width: 100%;
        }
        .bot-right-vector {
            right: -5px;
            img {
                height: 25px;
            }
        }
        .top-right-vector {
            position: absolute;
            top: 55px;
            width: 70%;
        }
        .text {
            padding-right: 0px;
            padding-bottom: 25px;
            .title {
                .gradiant {
                    font-size: 30px;
                }
                .normal {
                    font-size: 25px;
                    margin-left: 15px;
                }
            }
            .sub-title {
                font-size: 20px;
                margin-left: 15px;
            }
            .button {
                margin: 20px 0;
                a {
                    height: 50px;
                    width: 175px;
                    border-radius: 25px;
                    font-size: 15px;
                }
            }
        }
    }
    @media (max-width: 414px) {
        min-height: 400px;
        .text {
            padding-right: 0px;
            padding-bottom: 25px;
            .title {
                .gradiant {
                    font-size: 27px;
                }
                .normal {
                    font-size: 25px;
                    margin-left: 15px;
                }
            }
            .sub-title {
                font-size: 18px;
                margin-left: 15px;
            }
            .button {
                margin: 20px 0;
                a {
                    height: 50px;
                    width: 165px;
                    border-radius: 25px;
                    font-size: 14px;
                }
            }
        }
    }
    @media (max-width: 374px) {
        min-height: 320px;
        .navigation-banner {
            display: none;
        }
        .top-vector {
            left: -65px;
        }
        .bot-left-vector {
            width: 320px;
        }
        .text {
            padding-right: 0px;
            padding-bottom: 0px;
            .title {
                .gradiant {
                    font-size: 25px;
                }
                .normal {
                    font-size: 21px;
                    margin-left: 15px;
                }
            }
            .sub-title {
                font-size: 18;
                margin-left: 15px;
            }
            .button {
                margin: 40px 0 0;
                a {
                    height: 50px;
                    width: 165px;
                    border-radius: 25px;
                    font-size: 14px;
                }
            }
        }
    }
}
.banner-desktop {
  height: 100vh;
  width: 100vw !important;
  background-image: url('~@/assets/images/background/banner-desktop.jpg');
  background-repeat: no-repeat;
  background-position: left;
  background-size: cover;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-height: 800px;
  overflow: hidden;
}
.banner-mobile {
  height: 100vh;
  width: 100vw !important;
  background-image: url('~@/assets/images/background/banner-mobile.png');
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-height: 800px;
  overflow: hidden;
  margin-top: 50px;
}
</style>
