<script>
export default {
    data() {
        return {
            aboutImg: 100,
        };
    },
    mounted() {
        this.aboutImg = this.$refs.image.clientHeight;
    },
};
</script>
<template>
    <b-container fluid class="p-0">
        <div class="aboutUs-relative">
            <b-container>
                <div class="aboutUs">
                    <b-row class="align-items-center">
                        <b-col cols="12" lg="6">
                            <div class="aboutUs-img" data-aos="fade-right">
                                <!-- <img
                                    ref="image"
                                    src="~@/assets/images/about-us/aboutUs-img.jpg"
                                    alt=""
                                /> -->
                            </div>
                        </b-col>
                        <b-col cols="12" lg="6">
                            <div class="aboutUs-content">
                                <h3 data-aos="fade-up" class="text-uppercase">{{ $t('aboutUs') }}</h3>
                                <div class="aboutUs-item" data-aos="fade-up">
                                    <img
                                        src="~@/assets/images/about-us/aboutUs-icon.png"
                                        alt=""
                                    />
                                    <p>
                                        {{ $t('year2023') }}
                                    </p>
                                </div>
                                <div class="aboutUs-item" data-aos="fade-up">
                                    <img
                                        src="~@/assets/images/about-us/aboutUs-icon.png"
                                        alt=""
                                    />
                                    <p>
                                        {{ $t('marketTrend') }}
                                    </p>
                                </div>
                                <div class="aboutUs-item" data-aos="fade-up">
                                    <img
                                        src="~@/assets/images/about-us/aboutUs-icon.png"
                                        alt=""
                                    />
                                    <p>
                                        {{ $t('importantLink') }}
                                    </p>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </div>
            </b-container>
            <div class="bg-sub">
                <img src="~@/assets/images/about-us/mc-ab.png" alt="" />
            </div>
        </div>
    </b-container>
</template>

<style lang="scss" scoped>
.aboutUs-relative {
    width: 100%;
    position: relative;
    padding: 0px 0px 50px;
    min-height: 750px;
    display: flex;
    align-items: center;
    @media (min-width: 1400px) {
        .container {
            max-width: 1320px;
        }
    }
    .bg-sub {
        position: absolute;
        left: 0;
        margin: auto;
        bottom: calc(5% - 15px);
        z-index: 0;
        @media (max-width: 1200px) {
            bottom: calc(29% - 15px);
        }
        @media (max-width: 991px) {
            bottom: calc(50% - 15px);
        }
        @media (max-width: 768px) {
            bottom: calc(60% - 15px);
        }
        @media (max-width: 575px) {
            display: none;
        }
        img {
            width: 40vw;
            max-width: 320px;
            @media (max-width: 1200px) {
                width: 15vw;
            }
            @media (max-width: 991px) {
                width: 20vw;
            }
        }
    }
    .aboutUs {
        width: 100%;
        position: relative;
        z-index: 10;
        .aboutUs-img {
            box-shadow: -10px 10px 1px #0930ff;
            border-radius: 20px;
            height: 350px;
            background-image: url('~@/assets/images/about-us/aboutUs-img.jpg');
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            img {
                width: 100%;
                border-radius: 20px;
            }
        }
        .aboutUs-content {
            @media (max-width: 991px) {
                margin-top: 50px;
            }
            h3 {
                font-weight: bold;
                font-size: 40px;
                margin-bottom: 30px;
                @media (max-width: 991px) {
                    text-align: center;
                }
                @media (max-width: 575px) {
                    font-size: 30px;
                }
            }
            .aboutUs-item {
                display: flex;
                align-items: center;
                margin-bottom: 20px;
                img {
                    margin-right: 20px;
                    @media (max-width: 575px) {
                        margin-right: 10px;
                        width: 12px;
                        height: auto;
                    }
                }
                p {
                    margin-bottom: 0px;
                    color: #003189;
                    font-weight: 500;
                    @media (max-width: 575px) {
                        font-size: 14px;
                    }
                    span {
                        font-weight: bold;
                    }
                }
            }
        }
    }
}
</style>
